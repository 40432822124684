import React from 'react';
import { Route, Routes,BrowserRouter} from 'react-router-dom';
import Widget from './widget/Widget';
import RedirectF5Home from './shared/RedirectF5Home';

const App = ({}) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'  element={<RedirectF5Home />}  />
        <Route path='/error/*'  element={<Widget app='error' />}  />
        <Route path='/signin/*'  element={<Widget app='root'/>} />
        <Route path='/udf/*'  element={<Widget app='udf'/>}  />
        <Route path='/myprofile/*'  element={<Widget app='myprofile'/>} />
        <Route path='/ihealth2/*'  element={<Widget app='ihealth2'/>}  />
        <Route path='/ihealth/*'  element={<Widget app='ihealth'/>}  />
        <Route path='/advocacy/*'  element={<Widget app='advocacy'/>}  />
        <Route path='/myf5/*'  element={<Widget app='myf5'/>}  />
        <Route path='/learnf5/*'  element={<Widget app='learnf5'/>}  />
        <Route path='/f5labs/*'  element={<Widget app='f5labs'/>}  />
        <Route path='/community/*'  element={<Widget app='community'/>}  />
        <Route path='/partnercentral/*'  element={<Widget app='partnercentral'/>}  />
        <Route path='/cleo/error/*'  element={<Widget app='error' />}  />
        <Route path='/cleo/*'  element={<Widget app='cleo'/>}  />
        <Route path='/rockwell/*'  element={<Widget app='rockwell'/>}  />
        <Route path='/certiverse/*'  element={<Widget app='rockwell'/>}  />
        <Route path='*'  element={<Widget app='error' />}  />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
